export const photos = {
  august: [
    { src: "/magazine-z/en/skygunner-comic 0.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 1.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 2.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 3.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 4.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 5.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 6.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 7.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 8.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 9.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 10.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 11.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 12.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 13.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 14.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 15.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 16.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 17.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 18.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 19.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 20.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 21.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 22.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 23.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 24.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 25.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 26.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 27.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 28.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 29.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 30.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 31.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 32.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 33.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 34.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 35.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 36.jpg", width: 1, height: 1 },
  ],
  september: [
    { src: "/magazine-z/en/skygunner-comic 37.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 38.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 39.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 40.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 41.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 42.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 43.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 44.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 45.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 46.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 47.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 48.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 49.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 50.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 51.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 52.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 53.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 54.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 55.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 56.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 57.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 58.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 59.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 60.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 61.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 62.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 63.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 64.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 65.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 66.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 67.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 68.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 69.jpg", width: 1, height: 1 },
    { src: "/magazine-z/en/skygunner-comic 70.jpg", width: 1, height: 1 },
  ],
  hentai: [
    { src: "/くりとるぽこんつにゃ～/en/01.webp", width: 1, height: 1 },
    { src: "/くりとるぽこんつにゃ～/en/02.webp", width: 1, height: 1 },
    { src: "/くりとるぽこんつにゃ～/en/03.webp", width: 1, height: 1 },
    { src: "/くりとるぽこんつにゃ～/en/04.webp", width: 1, height: 1 },
    { src: "/くりとるぽこんつにゃ～/en/05.webp", width: 1, height: 1 },
    { src: "/くりとるぽこんつにゃ～/en/06.webp", width: 1, height: 1 },
    { src: "/くりとるぽこんつにゃ～/en/07.webp", width: 1, height: 1 },
    { src: "/くりとるぽこんつにゃ～/en/08.webp", width: 1, height: 1 },
    { src: "/くりとるぽこんつにゃ～/en/09.webp", width: 1, height: 1 },
    { src: "/くりとるぽこんつにゃ～/en/10.webp", width: 1, height: 1 },
    { src: "/くりとるぽこんつにゃ～/en/11.webp", width: 1, height: 1 },
    { src: "/くりとるぽこんつにゃ～/en/12.webp", width: 1, height: 1 },
    { src: "/くりとるぽこんつにゃ～/en/13.webp", width: 1, height: 1 },
    { src: "/くりとるぽこんつにゃ～/en/14.webp", width: 1, height: 1 },
    { src: "/くりとるぽこんつにゃ～/en/15.webp", width: 1, height: 1 },
  ],
};
