export const interview = [
  "== Aiming to create an unprecedented shooting game ==",
  {
    Interviewer:
      'Sky Gunner" is the first game from Pixel Arts, but was the plan set in stone from the beginning?',
  },
  {
    Emura:
      'It was chaotic when the members got together. At the time, we wanted to create a 3D action RPG that could be played by multiple players at the same time and that would have enemies crawling out of the screen, but to be honest, it was difficult. We came up with many other plans and projects, but it was difficult to form a solid image just on paper, so I programmed the ideas that came up. Among them was the prototype of the "Sky Gunner," in which the player\'s vehicle is an airplane. It was a prototype based on the concept of creating a 3D shooting game that was more than just a flight simulator.',
  },
  { Interviewer: "What exactly was the concept?" },
  {
    Hiraki:
      "We wanted to break away from existing 3D flight games and create a shooting game with a new direction. We wanted to create a game that really feels like a shooter in a 3D space, and that's the foundation of \"Skygunner. The other important thing was the sense of being in the air. We wanted to create a slapstick atmosphere with lots of objects. In terms of the worldview, we didn't want to go for science fiction or modern military fiction that would appeal to enthusiasts, but rather a character-based game that would gently envelop the player.",
  },
  {
    Kotani:
      "To put it a little more simply, we started with the idea of making a shooting game that people who don't usually play shooting games would want to play just by seeing it! That's how we started. Then, we asked ourselves how we could make it so that people would pick up the game and play it, so we tried to create a worldview and characters that would be easy for anyone to accept, to make the screen look nice, to show the atmosphere properly, and so on. We created the game one by one, taking care of the things that Hiraki mentioned above.",
  },
  {
    Emura:
      "Until now, 3D shooters and flight games have mainly focused on stoic dogfights. That was well-received by the enthusiasts, but it didn't really catch on with the general public. So, from the perspective of what was wrong with flight objects and what was frustrating about playing them, we came up with a game system that was pleasant to play.",
  },
  {
    Nakamura:
      "When we first started planning this project, we were developing it on PlayStation.",
  },
  {
    Kotani:
      "One thing that hasn't changed since the beginning is the unique camera perspective that keeps the enemy constantly caught within the screen.",
  },
  {
    Hiraki:
      "I thought that one of the problems with 3D shooters was the slow tempo caused by the time spent while the enemy is out of sight. So I thought, how about a game where you are constantly looking at the enemy?",
  },
  {
    Kotani:
      "Putting the enemy in the screen makes it look more flashy, and you don't miss the shot-down scene.",
  },
  { Hiraki: "I thought it would be interesting from a visual standpoint." },
  {
    Kotani:
      "But the downside was that it made it difficult to see what was in front of your plane. So we decided to make it impossible to hit obstacles from the start, and the Flight Assist System was born. Then we decided that we didn't need to make the game balance too severe, so we created a crash avoidance system with a series of button presses. The main system was developed in this way.",
  },
  {
    Hiraki:
      "It would be boring to have a game where you get hit by a sudden attack and immediately get killed. Instead, it would be more fun to desperately press a series of buttons to revive yourself. Incidentally, in the development process, a player can successfully hit the button up to 320 times to avoid crashing. I adjusted the number of consecutive button presses required for revival, but once I hit the limit, my fingers burned from the frictional heat, and I was unable to hit the buttons for about three days. When my fingers healed, I said they were hot, again.",
  },
  {
    Interviewer:
      "What were some of the difficulties in the development process?",
  },
  { Nakamura: "The programmers had a lot of trouble." },
  {
    Kotani:
      "Above all, the problem of how to determine the hit detection was very difficult.",
  },
  {
    Emura:
      "We use a unique algorithm for judging hits, so we can calculate a considerable amount of hits simultaneously, but that is only for non-moving objects. For example, if there are several moving models with complex shapes, such as the Merveilleux and the Gauche, and if we set up the hit detection so that each model avoids its own plane and enemy planes, the processing speed would be very slow. It was very difficult to design the map and battleships while taking this into consideration. If we made it too complicated, it would not be a game. If it was too complicated, it wouldn't be a game, but if we didn't make it complicated, it wouldn't have the \"lively\" feel, which is one of the concepts of the game.",
  },
  { Hiraki: 'You kept saying, "The speed, the speed."' },
  {
    Emura:
      'That\'s because they ordered me saying "faster, faster" until the very end (laughs).',
  },
  { Nakamura: "You were maximizing the whole time (laughs)." },
  {
    Interviewer:
      "Speaking of complex models, I was surprised at the expression of clouds. You can really go into them, can't you?",
  },
  {
    Emura:
      "We have a programmer named Imai-kun who was in charge of all the soft modeling of clouds and water spray. I am very satisfied with that kind of softness, which has never been seen in games until now.",
  },
  { Nakamura: 'Clouds are one of the important facets of "Sky Gunner.' },
  {
    Hiraki:
      "From the very beginning, I was concerned about the screen effects unique to PlayStation. At that time, no other makers had tried to express clouds. We pushed them to create it.",
  },
  {
    Interviewer:
      "Were you able to create clouds like that from the stage of PlayStation development?",
  },
  {
    Emura:
      'The method of expression is completely different from today, but I thought that soft clouds were necessary for the "Sky Gunner" worldview, so I had done a lot of research in that area since that time.',
  },
  {
    Nakamura:
      "With PlayStation, you used up all of the machine's performance just to create clouds.",
  },
  { Kotani: "It really wasn't a game at all (laughs)." },
  {
    Emura:
      "That's right. When 20 clouds appeared, it stopped being a game (laughs).",
  },
  "== The particular attention to detail ==",
  { Interviewer: "What in particular did you want players to notice?" },
  {
    Hiraki:
      "I would say everything, but I would say the sheer number of things. I think you can enjoy a sense of funkiness or slapstick that you won't find in any other game.",
  },
  {
    Emura:
      "It's hard to get a sense of distance without something that contrasts with the size and speed of your plane. That's why we created a city, clouds, huge battleships and airships, and many other objects to give the viewer a natural sense of space. Also, we created a lot of enemies. We want players to experience the feeling of shooting down enemy planes as they fly by.",
  },
  {
    Interviewer:
      "There is no compromise. There are no lies in what is happening in the space. The other players are all doing their missions, right? I think this is a part of the game that would have been skipped in previous games.",
  },
  {
    Kotani:
      "When Copain is rolling, you can see it clearly, especially from a distance. You can tell it is rolling.",
  },
  {
    Hiraki:
      "It is possible because there are various objects in the space and they are all moving around. Personally, I like it very much.",
  },
  {
    Nakamura:
      "The fact that your friends are both collaborators and competing for prize money is part of what makes the game interesting.",
  },
  {
    Hiraki:
      "There are many times when I feel like they are in the way (laughs). (laughs). For example, when they use heavy fire to defeat the enemy on their own.",
  },
  {
    Emura:
      "When I'm waiting to try to get the chain, they just snatch it right out from under my nose. That Copain guy! (laughs).",
  },
  {
    Interviewer:
      "Of course, there are a lot of things in the game, but above all, the production and the level of detail is amazing, isn't it? A small Poulet walking on the slope of a nearly 2-kilometer battleship, people standing in the city, and so on.",
  },
  { Emura: "It's quite a crack-up, though. People in the city." },
  {
    Kotani:
      "Actually, we wanted to do a lot more. At first, they were all postmen.",
  },
  {
    Interviewer:
      "In Femme's Scene 3, when Vanquer comes after the telescope, the people of the town seem to be enjoying themselves around the river, don't they?",
  },
  {
    Hiraki:
      'I guess all the residents of Rive\'s love festivals. In "Sky Gunner," we try not to have people die at all. I didn\'t want the game to be too realistic and bleak, so I decided from the very first concept that no living creatures would die. That was another important aspect of "Sky Gunner.',
  },
  { Kotani: "We also decided not to destroy the buildings in the city." },
  {
    Nakamura:
      "We value even each and every single Poulet. When we shoot them down, they open their parachutes and escape. I would be attracted to the sight of a bloodied downed Poulet (laughs).",
  },
  {
    Hiraki:
      "Well, I wasn't that conscious about it at first, but if you pay close attention, you can see that there are a lot of little details here and there.",
  },
  {
    Watanabe:
      "In scene 1, in the Merveilleux, is it difficult to notice that a Poulet is attacking the greenhouse under the hull?",
  },
  {
    Nakamura: "If you look closely, there are many things related to Poulets.",
  },
  {
    Interviewer:
      "When a part of the battleship is broken, he comes out to the broken part and fixes it with a hammer, saying \"I'll repair it. Poulet jumping out of the assault pod was also interesting (laughs).",
  },
  {
    Hiraki:
      "It wasn't well received within the development team, saying it wasn't interesting from an event standpoint, but I like it as a situation. There is a cushion inside the assault pod, and it is also expressed with texture. It's hard to see (laughs).",
  },
  {
    Emura:
      "There is also a Grandir with a broken shaft and a tilted fuselage, and a Poulet is hanging from it.",
  },
  { Hiraki: "He hangs on desperately for a while, but then he slides off." },
  {
    Kotani:
      "I was saying how I wanted him to fall, and before I knew it, he was doing it.",
  },
  {
    Hiraki:
      "That's the kind of thing Imai-kun, who was in charge of direction, insisted on. I was surprised that he went to such lengths (laughs).",
  },
  {
    Interviewer:
      "When you damage Poulet's aircraft, such as Premiere and Progress, the livestock sounds, flower pots, pods, tea cups, umbrellas, etc., fly all over the place, don't they? What are those things?",
  },
  { Hiraki: "Those things are Poulet's treasures (laughs)." },
  {
    Watanabe:
      "Even during operations, Poulets take their treasures with them with great care (laughs).",
  },
  { Interviewer: "Dog missiles have faces drawn on them, too, don't they?" },
  {
    Kotani:
      "It's shaped like a dog. During development, there was a bone chaff or something for Rival as a counter-weapon to the dog missile. When the dog missile sees that bone, it goes after it.",
  },
  {
    Interviewer:
      "I was also surprised that, when Rival uses his EX action heavy fire, exactly 16 bullet cartridges fall.  And that the time on the clock tower in the city matched the time set in the scene.",
  },
  {
    Hiraki:
      'I thought, "Normally people wouldn\'t notice something like this," but I included it. But I guess that\'s part of what makes "Sky Gunner" so unique. It\'s really thanks to the attention to detail that all the designers paid.',
  },
  "== Attractive Characters ==",
  {
    Interviewer:
      "The atmosphere of the characters and illustrations is one of the most attractive aspects of this game.",
  },
  {
    Watanabe:
      "I was very conscious of approaching people who do not usually play games. Hiraki told me to aim for a silhouette that is easy to understand so that people who see the character can get into the game and draw a portrait of the character later. In the end, however, I was able to do whatever I wanted. It was full of my hobbies.",
  },
  { Hiraki: "You did it!" },
  { Kotani: "They are cute, but they are not overly flirtatious." },
  {
    Watanabe:
      "I think the Poulet in particular is a character that shows that.",
  },
  {
    Interviewer:
      "Speaking of that, why do the people living in this world have tails?",
  },
  {
    Watanabe:
      "This is a remnant from the initial character design. In the beginning, we were designing them based on the concept of bird people, but as development progressed, they gradually took on the shape they have now. At first, it was much more animal-like in appearance.",
  },
  {
    Kotani:
      "You didn't want the character to be merely a deformed version of an animal, did you?",
  },
  { Hiraki: "That's right." },
  {
    Kotani:
      "I guess they originally evolved from something different from humans, but they are a different species of human, not an animal.",
  },
  { Hiraki: "Well, I think we evolved in a good direction (laughs)." },
  { Interviewer: "Do you have an age set for each character?" },
  {
    Hiraki:
      "Somewhat, but I have an idea based on Ciel. In human terms, Ciel is in his mid-teens, Copain is one or two years older, Femme is three or four years older, and Rival is a little further up from there. Hardi is in his late 40s, and Ventre is about 60 years old.",
  },
  {
    Interviewer:
      "Femme is the oldest of the main characters, isn't that right? Does Femme see Ciel and Copain as children?",
  },
  {
    Hiraki:
      "In terms of her character, I don't think she cares that much. They're just a lower age.",
  },
  {
    Kotani:
      "But they are at a high level as gunners. Ciel and Copain are. I think Femme also recognizes their abilities.",
  },
  { Watanabe: "Ciel is a nice and cheerful kid." },
  {
    Hiraki:
      "Copain and Femme push Ciel. He is a leader who organizes things well. He must have a personality that is well-liked by everyone.",
  },
  { Emura: "He is serious and responsible." },
  {
    Hiraki:
      "But he is still childish. He is boyish, or rather, he likes machines and inventions.",
  },
  { Interviewer: "Compared to that, Copain is..." },
  {
    Hiraki:
      "Copain loves girls. He is also a fun-loving person. He doesn't do anything complicated. That is why he leaves the maintenance of his flying boat to the manufacturer.",
  },
  { Nakamura: "He hates troublesome things." },
  {
    Interviewer:
      "Does Copain like to listen to the radio? It seems like he always carries it with him.",
  },
  {
    Watanabe:
      "Copain has his own radio. Even at the beginning of scene 2, he has it with him on the freighter.",
  },
  {
    Hiraki:
      "It's not that he just likes to listen to the radio, but I think he listens to the radio to gather information to see if there is anything interesting.",
  },
  {
    Interviewer:
      "What is written on the memo in the cockpit of the Chevalier in which Copain rides?",
  },
  {
    Watanabe:
      "I am sure that that note has something to do with girls. I am absolutely sure of that (laughs).",
  },
  {
    Nakamura:
      "So you write down contact information, birthdays coming up, and other things so that you don't forget about minor business (laughs).",
  },
  {
    Kotani:
      "Moreover, the notes are not always from the same girl. It changes when I notice it (laughs).",
  },
  {
    Interviewer:
      "Is the Gunner's Cafe in the town of Rive open to regular customers? Is it only for gunners?",
  },
  {
    Hiraki:
      "Normal customers also come. The Gunner's Cafe is only called the Gunner's Cafe because Ciel and Copain live there, but I am sure it has a real name. It is by no means a restaurant exclusively for gunners. It was probably just Ciel at first, but Copain came to live there later, so that's why it's called that.",
  },
  { Kotani: "Unwanted regular customers (laughs)." },
  {
    Hiraki:
      "So it's a place where regular customers can come and stay, and anyone can stay here if they want to.",
  },
  {
    Interviewer:
      "So the owner or manager of the Gunner's Cafe was originally a gunner, so does he or she have an understanding of Ciel and the others who do as they please?",
  },
  {
    Hiraki:
      "I don't know about that. Gunner is a profession that everyone in this world aspires to, and there are very few of them. They must be very much coveted.",
  },
  { Emura: "Copain became a gunner just to be admired (laughs)." },
  {
    Interviewer:
      "There is a maintenance shop in the back of the store, isn't there?",
  },
  {
    Hiraki:
      "In a world like this, a hotel must have facilities for maintenance of airboats.",
  },
  { Interviewer: "Are there dedicated mechanics?" },
  {
    Hiraki:
      "No, there aren't such people, especially at Gunner's Cafe. Gunners usually don't let other people touch their flying boats.",
  },
  {
    Interviewer:
      "I heard that Ciel's grandfather built the Avenir.  Did his father fly it in his time?",
  },
  {
    Hiraki:
      "I am not sure about that. Maybe his father had a dedicated aircraft.",
  },
  {
    Interviewer:
      "If his grandfather has that connection with the Avenir, does that mean that being a gunner has been Ciel's family business?",
  },
  {
    Watanabe:
      "I don't know. I don't think Ciel's father is a gunner, but his mother is (laughs).",
  },
  { Interviewer: "Are there many female gunners?" },
  {
    Hiraki:
      "It's not that women can't be gunners, but the number is still small. Femme also became a gunner not because she wanted to, but because she was discovered to have the right qualities.",
  },
  { Watanabe: "Who might she have admired? ......" },
  {
    Hiraki:
      "Oh, that's gibberish (laughs). Well, there was a gunner who was close to Femme, and she may have been influenced by him.",
  },
  {
    Interviewer:
      "Why is Femme, who is new as a gunner, entrusted with guarding the Merveilleux?",
  },
  {
    Hiraki:
      "Maybe it's just a bad temperament of the Horloger company, but they are using her as a good billboard for the company by providing a new model machine to a female gunner, which is still very rare. I think Femme also accepted the job because she knew Hardi, who was in charge of security.",
  },
  { Interviewer: "In the beginning of scene 1, Femme has long hair." },
  {
    Watanabe:
      "Yes. That's how it's set up. I didn't mind it when I was first drawing it, but when I tried to draw the scene where she unties her hair, I thought, \"Oh, this is too short\" (laughs).",
  },
  {
    Interviewer:
      "Is Femme more relaxed than Ciel, Copain and the others because she is older than them?",
  },
  {
    Hiraki:
      "Yes, she is. She may be the most solid person, but she is not so serious. Sometimes she is bold. She is a person who can take appropriate action depending on the situation. She is sociable and probably very popular with those around her. She is probably a beautiful woman in that world.",
  },
  { Interviewer: "Copain doesn't he show any interest in Femme?" },
  { Watanabe: "I don't know about that." },
  {
    Hiraki:
      "Maybe Femme is not his type. I am sure Copain's type is a girl who is lighthearted and easygoing, like himself.",
  },
  { Interviewer: "Rival is one of the richest men in the world, isn't he?" },
  { Hiraki: "He is one of the richest people in that world." },
  { Emura: "One of the remaining millionaires is Ventre, though." },
  {
    Interviewer:
      "I heard that the Fant\u00f3m, which Rival rides, and the Avenir, which Ciel rides, are brother machines.",
  },
  {
    Watanabe:
      "They were made around the same time, but Rival customized the Fant\u00f3m to his liking, while Ciel kept the initial shape of the Avenir and used it carefully.",
  },
  {
    Hiraki:
      "The original Fant\u00f3m probably had a similar shape to Ciel's, but Rival must have had a lot of money and modified it a lot, saying he didn't need this part or that part. Fant\u00f3m will probably never remain in the same form.",
  },
  {
    Interviewer:
      "In the game, when you lose the showdown with Ciel, Rival immediately changes the Fant\u00f3m's shape.",
  },
  {
    Nakamura:
      "Taking unnecessary parts to the limit in order to win is like taking a mini 4-wheel drive to the extreme (laughs).",
  },
  { Watanabe: "The cockpit area has only a control stick and gauges." },
  {
    Hiraki:
      "I think Rival has a natural talent for sensing the condition of the aircraft.",
  },
  { Kotani: "That's why he falls off so easily (laughs)." },
  { Interviewer: "Does Rival's personality change when he wears glasses?" },
  {
    Hiraki:
      "The glasses have nothing to do with it, but his personality is such that he loves to fight, so when he is in that kind of situation, his head is screwed up. Just as some people's personalities change when they get behind the wheel. Well, when you become a gunner like him, you get requests from all kinds of places, not only his home base in Neige, but he goes wherever he needs to go, but he only does work that satisfies his own desires. For Rival, the criterion for accepting a job is not the amount of money, but how much it will entertain him.",
  },
  { Emura: "It's a form of road trip, isn't it?" },
  {
    Hiraki:
      "There is also some kind of underground black market world, and he also takes on risky jobs.",
  },
  { Interviewer: 'Are you referring to "that person" in Rival\'s story?' },
  {
    Hiraki:
      "I can't go into details, but he is the one who is involved with the parts built into the perpetual motion machine.",
  },
  {
    Interviewer:
      "Did the Horloger company create the perpetual motion machine?",
  },
  {
    Hiraki:
      "The Horloger company only financed the development of the perpetual motion machine, but it was made by another company.",
  },
  {
    Watanabe:
      "I am sure that the person who asked Rival to recover the perpetual motion machine was at the party in Merveilleux.",
  },
  {
    Interviewer:
      "Speaking of which, if you look closely at the illustration in the prologue, you can see that Ventre is also riding on the Merveilleux from the beginning.",
  },
  {
    Hiraki:
      "Yes, he is. But he was in the VIP room, not at the party where they were showing off the perpetual motion machine.",
  },
  { Interviewer: "Ventre is not a well-known face?" },
  {
    Hiraki:
      "He is a special kind of celebrity, so there are people who know him. Most people have forgotten who he is, since he only appeared for a year 10 years ago. Most people have forgotten about him, but they remember him when they hear about him. Besides, he is probably well known in the areas where he was violent, but not so well known in other places.",
  },
  {
    Interviewer:
      "Did Inspector Hardi, who was guarding the party site, only know the name of Ventre?",
  },
  {
    Emura:
      "Actually, he came in contact once before. But at that time, he missed him.",
  },
  {
    Hiraki:
      "Even in the game, Hardi was not allowed to enter the VIP room of the Merveilleux, so he never met Ventre. He is just in charge of security at the venue.",
  },
  {
    Watanabe:
      "Well, Ventre must have used a false name when he entered Merveilleux. Ventre is a character who became more and more rounded as I drew him (laughs).",
  },
  { Kotani: "No matter which way you look at him, he is round." },
  { Watanabe: "His tail has grown old and lost its tautness." },
  { Kotani: "He had a beautiful tail when he was young." },
  {
    Interviewer:
      "Was Ventre always rich?\u3000Or did he become rich after the incident 10 years ago that has become legendary?",
  },
  { Hiraki: "He is a ridiculously wealthy man from the start." },
  {
    Interviewer:
      "Is Ventre the type of person who finds his purpose in crime, just as Rival does in fighting?",
  },
  { Hiraki: "No, he is not that type." },
  { Kotani: "In a word, he is the king of greed." },
  {
    Hiraki:
      "He doesn't steal with such bad intentions. For example, if he wants a perpetual motion machine, he thinks it belongs to him in the first place at that point. So he thinks it's strange that it's not in his possession, so he takes it.",
  },
  {
    Watanabe:
      "With Ventre, it would probably be cheaper to buy various things with money (laughs). In scene 3 of Copain, Exoce steals a lamppost, and it's for decorating the Grand Magasin. If you look closely at Grand Magasin in scene 5, you will discover the streetlight.",
  },
  {
    Nakamura:
      "When the astronomical telescope is stolen by the mechanical art Vanquer in scene 3 of Femme, it is also installed in Grand Magasin.",
  },
  {
    Hiraki:
      "Vanquer itself is also stolen. The Squelette was also stolen using Poulets. However, he didn't use it as it was, but he modified the design himself.",
  },
  {
    Interviewer:
      "Are Poulets the ones who actually assemble the weapons that Ventre uses?",
  },
  {
    Hiraki:
      "Ventre draws up the blueprints and then has Poulets make the rest.",
  },
  { Interviewer: "Ventre has only Poulets as his employee?" },
  { Hiraki: "Only Poulets work for him. It's lonely (laughs)." },
  {
    Watanabe:
      "Well, but he has a family or something. In the setting stage, there was a granddaughter, too.",
  },
  { Interviewer: "So Ventre has a family?" },
  { Hiraki: "If he didn't, the character would be too dark." },
  {
    Kotani:
      "Actually, he looks like a grandfather and he is very warm-hearted toward his grandchildren.",
  },
  {
    Hiraki:
      "I wonder. He is a member of the Ventre family, so I guess everyone in the family is a little off (laughs).",
  },
  { Interviewer: "Poulets have ranks?" },
  {
    Hiraki:
      "There are two stewards, Poulet, who report directly to Ventre. They are the caretakers of the Ventre, and their duties include making tea and answering the phone. And if an ordinary Poulet does a good job, he or she is treated as a captain Poulet.",
  },
  { Interviewer: "Do each Poulet have their own personality and feelings?" },
  { Hiraki: "Yes, they do." },
  {
    Interviewer:
      "Poulet who make good achievements in operations are promoted, right? On the other hand, are there demotions?",
  },
  {
    Hiraki:
      "Of course. If a captain slacks off too much, he will be chastised and become a regular, ordinary Poulet.",
  },
  {
    Kotani:
      '"Bonus!" is something they say, but I wonder what they spend the money on. Do they get a vacation?',
  },
  { Watanabe: "I wonder." },
  { Interviewer: "How many Poulets are there?" },
  { Watanabe: "There are more than 100 at the moment." },
  {
    Hiraki:
      "In fact, the number of those that appear in the game probably won't be more than 100 (laughs).",
  },
  {
    Interviewer:
      "Poulets, being the perfect automata, are supposed to never break. So what happens to a Poulet and his friends who sink underwater?",
  },
  {
    Watanabe:
      "They will manage to come back to Mr. Ventre by themselves, won't they?",
  },
  { Hiraki: "By walking along the bottom of the water." },
  {
    Interviewer:
      "Even if a Poulet loses to Ciel and the others and the operation fails, he won't learn from it, will he?",
  },
  { Kotani: "He's a perfect automata (laughs)." },
  {
    Hiraki:
      "From Ventre's point of view, Poulets are in a perfect state at the stage of their creation. They don't need to learn anything new because it's perfect.",
  },
  {
    Kotani:
      "If you make improvements, at that point it is no longer perfect. That's why he doesn't want to do it (laughs).",
  },
  {
    Hiraki:
      "In the game, Ciel and his team are excellent and drive away Poulets, but against ordinary gunners, Poulets are stronger.",
  },
  {
    Watanabe:
      "In fact, Poulets kicked the shit out of ordinary gunners 10 years ago. Ventre built Poulets just before he went into action, and he used Poulets for a year and went on a rampage. Captain Hardi had a run-in with Ventre at that time. But Hardi never saw Ventre's face. At the time, Hardi was a lieutenant, but he didn't have much authority. If he had been a police inspector back then, he might have been able to catch him. After that, Ventre stopped working. I wonder why? Maybe he had been building the Grand Magasin since then?",
  },
  {
    Hiraki: "It took 10 years to build it, but it fell in one night (laughs).",
  },
  {
    Emura:
      "Hardi was a lieutenant 10 years ago, does that mean he was a gunner when he was in his 20s or so?",
  },
  {
    Watanabe:
      "Why would Hardi stop being a gunner? When you think about it, he is a bit of a mystery too.",
  },
  {
    Kotani:
      "Isn't gunner a profession that you have to be young to do? He's rootless. His wife says to him, \"It's time for you to stop being a gunner\" (laughs).",
  },
  {
    Watanabe:
      "Speaking of Hardi's daughter, Fille, when I first drew her, she didn't have a bounce in the back of her hair. I added it later (laughs).",
  },
  {
    Hiraki: "There were many twists and turns before this girl was completed.",
  },
  {
    Kotani:
      "The bounce in the back of her hair is proof of Hardi's DNA (laughs). I bet people around her say, \"Oh, Fille-chan, you look just like your father!",
  },
  { Interviewer: "Watanabe, did you work on the mechanics as well?" },
  {
    Watanabe:
      "Not all of it. About half of it, I think. For the main character's aircraft, I drew what I thought might be a good design at first, and worked out the details with the modeler who actually made the model. We made a 3D model.",
  },
  {
    Hiraki:
      "We went through many steps. To tell the truth, I am not very familiar with the actual aircraft. Because I am not very familiar with the actual aircraft, I think I was able to freely and conveniently use only the tasty parts of the airplane.",
  },
  {
    Watanabe:
      "I put emphasis on the appearance of the planes. Each plane has a concept and is designed based on that concept. For Avenir, we decided to equip her with a big gun because the game is called Sky\"gunner\". Chevalier was inspired by a knight's armor and spear. Blanche was inspired by a bird's wings.",
  },
  {
    Interviewer:
      "What is the level of technological progress in that world in terms of the Earth?",
  },
  {
    Hiraki:
      "I imagine it to be about the same as the Industrial Revolution of the 19th century. That's why steel frames are a new culture and so on.",
  },
  { Interviewer: "Are flying machines common?" },
  {
    Hiraki:
      "Yes, they are. In terms of civilization, the world is more developed by air than by land or sea. That is why flying boats and airships are very common. They fly casually to go to the neighborhood. In the game, ordinary airplanes are only seen in the opening animation.",
  },
  {
    Kotani:
      "It's a world where flying is not difficult, and when you try it, you can kind of fly.",
  },
  {
    Hiraki:
      "I don't really understand the theory behind it, but that's how it is (laughs).",
  },
  {
    Interviewer:
      "There are many flying boats that take off and land from the water, but there are no airplanes that require a runway?",
  },
  {
    Hiraki:
      "Yes, there are. It just so happens that this time the setting is the waterfront city of Rive, so there are many flying boats, but you can think of it as there being many different types of flying machines.",
  },
  {
    Interviewer:
      "What are the origins of the names of the characters and aircraft?",
  },
  {
    Hiraki:
      "The names of the characters and aircraft are based on French words, but I only took the sound of the words and changed the actual spellings for words that were difficult to read. I was very careful to make the name short and sound good, so that it would stick in the mind immediately.",
  },
  {
    Kotani:
      'The meaning is pretty straightforward, though. The tanks are called "char" or something like that. It\'s exactly like that (laughs).',
  },
  {
    Nakamura:
      'When French people play, when they fight Gauche and Deloitte, they say, "Beat the right side! Down the left!" I wonder if they would be like that (laughs).',
  },
  "== Directly from the development staff. Astonishing prize money earning techniques ==",
  { Interviewer: "What is the secret of earning prize money?" },
  {
    Hiraki:
      "Above all, don't crash. While crashing, you lose a lot of prize money, and losing time and losing bonus counts hurts more than anything else. If you lose your balance and hear a warning sound, don't push yourself too hard.",
  },
  {
    Interviewer:
      "What are the key points of bounty hunting for each character? Let's start with Femme.",
  },
  {
    Hiraki:
      "Femme has a very important point in scene 4. It is the first half of the fight against the defense team and the last scene against Squelette.",
  },
  {
    Interviewer:
      "When you are defeating the defenders, do you go off to the side to defeat the fortress canons?",
  },
  {
    Hiraki:
      "Yes, that's right. It is true that you can make a good amount of money with the cannons, but it is enough just to take take down the cannons that with chain bonuses. From an efficiency standpoint, what is more important is to increase the bonus multiplier to about x30 with the detroit in the moat in order to earn money later on in the Squelette battle. In that state, you can make 6-7 million or so at a time by destroying the Squelette's continuous machine gun and getting a chain bonus. If the bonus multiplier goes down, there is a place in the back where tanks appear, so you can further increase the bonus multiplier there in the same way and make money with the chain again. Either way, it is a matter of how much you can increase the multiplier in a short period of time.",
  },
  { Interviewer: "What is the key to earning money with Ciel?" },
  {
    Hiraki:
      "Ciel is more difficult to earn than other characters. The battle with the group of stables in scene 2 is important. You need to quickly kill some of them with cross missiles to increase the bonus multiplier, and then use fireworks missiles to destroy all the flanking guns at once to earn money from the hits. The trick is the use of optional weapons. You can also devise a chain in the command tower of the Grand Magazan in scene 5. Instead of chaining from the lower turret, chain from the upper turret downwards to chain repeatedly while limiting the damage to the command tower.",
  },
  { Nakamura: "If you do it too long, you will run out of fuel." },
  {
    Hiraki:
      "In scene 5, you cannot earn a large bounty if your friends break the chain. You have to be willing to break all the couplers by yourself. Femme and Copain are always looking for a new target, so you have to be ready to go around breaking all the couplings.",
  },
  { Interviewer: "How do you make money with Copain?" },
  {
    Hiraki:
      "The first requirement is how efficiently you succeed in the cannon destruction mission in Scene 4. When Exoce Gold and Exoce Red create a circle in Scene 3, it is also important how many child machines you can combo with pumpkin bombs. If you do well, you can get 12 to 14 hits. It's no good if you don't increase the bonus multiplier, so until that point, take out the child planes, detroiters, and assault pods to earn the bonus count. I also recommend that you earn money by taking down the assault pods in scene 2 to raise the bonus multiplier, and then take down the formation of Premiers that shot out the pods all together. Since these Premiers fly in a straight formation, you can get 7 to 10 hits by rolling and ganging up on them.",
  },
  { Interviewer: "You don't see the Premiers on the target, do you?" },
  {
    Hiraki:
      "That's right. But you can't make good money unless you knock down invisible enemies. The key to Copain is to use rolling. Also, the fireworks missiles have a wide range of explosion when locked at maximum, so when the large force of Premiers comes to attack the cargo ship in Scene 2, you can engulf most of them at once if you lock at maximum from behind instead of hitting them with fireworks missiles from the front. It is also important to take out the Balene before Ciel.",
  },
  {
    Kotani:
      "It doesn't show up in the target select (laughs). You have to look for it?",
  },
  {
    Hiraki:
      "Yes. You have to look for it. When you roll into Balene, most of the parts break, so you have to cut back and roll into her again while not overheating. Then you have to rush back to the assault pod and earn your money. As you get used to it, you will be able to know where the pods are, and once you do, you will be able to make a lot more money.",
  },
  { Nakamura: "I didn't know that (laughs)." },
  {
    Hiraki:
      "There are points where you can earn unexpectedly if you deviate from your usual way of doing things and try various things. The game is designed so that it is not just a game of patterns, so please discover various things and aim to increase your winnings.",
  },
  "== About the sequel ==",
  { Interviewer: "Do you have any plans for the next installment?" },
  { Nakamura: "Yes, we do. It is still in preparation." },
  {
    Hiraki:
      "There are many things we have left to do. Personally, I think it is not yet complete.",
  },
  { Kotani: "I really wanted to play with multiple players at the same time." },
  {
    Nakamura:
      "That's exactly what it would be like to fight for points. Why are my allies shooting at each other?",
  },
  {
    Emura:
      "Chevalier's rolling is the strongest (laughs). 3D shooting has given me an unprecedented sense of exhilaration, and I have a feeling that it will open up more new worlds.",
  },
  {
    Kotani:
      "In a game, a slight change in the flow or rhythm can change the necessary elements. There are still many parts of the system that are borrowed from existing games, so I feel that there is still a bit of dissonance. Now that we know what kind of things we can do, I would like to pursue a system that is suitable for that. I hope I can use my experience this time around to make it more fun. But I guess half of me wants to do a sequel, and half of me wants to create something different.",
  },
  {
    Emura:
      'If we can come up with a good idea, I guess. I don\'t know if the next work will be a successor to "Sky Gunner," though. But this world has a unique atmosphere and is very appealing, so I would like to preserve it as much as possible.',
  },
  { Kotani: "I would like to continue making games without blood (laughs)." },
];
