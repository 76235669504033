export const unlockPhotos = `Album

This mode lets you enjoy two types of photos: event photos taken when you clear a story mission, and commemorative photos taken by Ventre when the game is over. You won't have all of them when you clear Femme's story, but you can fill in the gaps by fulfilling the following conditions. Event photos are basically collected by successfully completing the given missions, but to take photos 4 in scene 2 and 4 in scene 3, you must fail the previous mission and clear the added mission.

How to get Ventre's commemorative photos
1. Game over occurs in Ciel's story
2. Game over occurs in Copain's story
3. Game over occurs in Femme's story
4. Game over occurs in Rival's story
5. Merveilleux is stolen in Ciel and Copain's scene 1
6. The cargo fleet is wiped out in scene 2
7. In scene 4, the danger level of Hardi's armored car reaches 100%, or its power output drops and it falls into the lake
8. In Femme's scene 5, Ciel's danger level during his attack on the Command Tower reaches 100%
9. In Ciel and Copain's scene 5, Femme's danger level reaches 100% while recapturing the museum
10. In scene 5, a game over occurs after the second stage of Vanquer Garde's rampage.

SCENE 1 How to unlock event photos
1st Mission: Ciel 1-1, Copain 1-1
Complete "Defeat enemy fighters"
2nd Mission: Ciel 1-2
Complete "Defeat the stable in front of the hangar" before Femme leaves Merveilleux on her own
3rd Mission: Copain 1-2
Complete "Defeat the mechanical soldier on the deck"
4th Mission: Femme 1-1
Complete "Destroy the hangar door"
5th Mission: Femme 1-2
Complete "Defeat enemy fighters"
6th Mission: Femme 1-3, Ciel 1-3, Copain 1-3
Defeat the last tow cannon during the mission "Defeat the defense force protecting the tow cannons" for Femme, and "Destroy the tow cannons and recover the Merveilleux" for Ciel and Copain.

7th Mission: Femme 1-3
Complete "Defeat the defense force protecting the tow cannons"

8th Mission: Femme 1-4
Complete "Destroy the Gauche's cannons and support Ciel"

SCENE 2 How to unlock event photos

1st Mission: Femme 2-1
Complete "Defeat the enemy fighters and protect the cargo fleet"

2nd Mission: Ciel 2-2
Complete "Defeat the Balena and protect the cargo fleet"

3rd Mission: Copain 2-2
Clear "Defeat the assault pods and protect the cargo fleet"
4th Mission: Copain 2-3
Clear "Drive away Poulets with dog missiles"
5th Mission: Femme 2-2
Clear "Defeat the Stable and enemy fighters and protect the cargo fleet"
6th Mission: Ciel 2-3
Clear "Defeat the fleeing Stables"
7th Mission: Femme 2-3
Clear "Defeat the Grandir"
8th Mission: Femme 2-3, Ciel 2-4, Copain 2-3
Destroy the lightning generator and clear "Defeat the Grandir"
131

SCENE 3 How to unlock event photos
1st Mission: Ciel 3-1
Shoot down the Fantôme and complete "Defeat the Black Gunner"
2nd Mission: Copain 3-1
Shoot down the Exocet Blue and complete "Defeat the Exocet before it leaves the waterway"
3rd Mission: Femme 3-1
Complete "Stop the Machine Soldier Invasion" before the telescope is stolen
4th Mission: Femme 3-2
Complete "Recover the telescope from the Machine Soldier"

SCENE 4 How to unlock event photos
1st Mission: Femme 4-1
Complete "Defeat the Enemy Formation"
2nd Mission: Ciel 4-2
Shoot down the two Dorados within the time limit and complete "Defeat the Dorados guarding the fortress"
3rd Mission: Mission: Copain 4-2
Destroy all the fortress cannons within the time limit and complete "Destroy the cannons protecting the fortress"
4th Mission: Femme 4-2
Destroy the defense force's Dernier 3 units within the time limit and complete "Defeat the defense force protecting the fortress"
5th Mission: Femme 4-3
Complete "Protect the armored car from enemy attacks"
6th Mission: Femme 4-4
Complete "Defeat the Squelette while protecting the armored car"

SCENE 5 How to unlock event photos
1st Mission: Ciel 5-1
Complete "Defeat the enemy fighters that are dispatched"
2nd Mission: Copain 5-1
Complete "Defeat the enemy fighters that are dispatched"
3rd Mission: Ciel 5-2
Complete "Defeat the Dorado and protect Femme from the mechanical soldier"
4th Mission: Copain 5-2
Complete "Defeat the Dorado and protect Femme"
5th Mission: Femme 5-1
Complete "Destroy the tethers parts and take back the museum"
6th Mission: Femme 5-2
Complete "Defeat the tether repair cannon"
7th Mission: Femme 5-3
Complete "Destroy the couplers and dismantle the Grand Magasin"
8th Mission: Ciel 5-3
Shoot down the Fantôme and complete "Defeat Rival"
9th Mission: Ciel 5-4
Without destroying the engine and complete "Destroy the command tower" within 150 seconds
10th Mission: Copain 5-4
Complete "Destroy the parts and destroy the engine room" within 150 seconds
11th Mission: Femme 5-4
Complete "Defeat the enemies and protect Ciel"
12th Mission: Femme 5-5
Complete "Perform a successful coordinated attack with your allies"
13th Mission: Femme 5-6
Complete "Attack the Eternal Engine and defeat the machine soldier"
14th Mission: Femme 5-7, Ciel 5-7, Copain 5-7
Complete "Defeat Ventre's battleship"
15th Mission: Rival 2-2
Complete "Attack the Eternal Engine and defeat the machine soldier"`;
